<template>
  <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="gridItem"
          title="평가항목"
          tableId="gridItem"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="gridItem.columns"
          :data="gridItem.data"
          :gridHeight="gridheight"
          :isExcelDown="false"
        >
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                outline square
                :clickable="true"
                color="blue"
                text-color="white"
                style="margin-top:3px !important;"
                @click.stop="() => { rowClick(props.row) }">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
      <c-table
        ref="gridImpr"
        title="평가항목별 개선목록"
        tableId="gridImpr"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :editable="true"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :merge="gridImpr.merge"
        :gridHeight="gridheight"
        @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId, 'item')"
      >
          <template slot="table-chip">
          <q-btn-group outline >
            <q-chip v-if="isRowClick" outline square>
                {{ selectedItemRow.itemMstName}}
            </q-chip>
          </q-btn-group>
        </template>
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled && isRowClick" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr('item', 'ITTU000060')" />
            <c-btn v-if="editable && !disabled && isRowClick" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr('item', 'ITTU000060')" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridOtherImpr"
        title="항목외 개선 목록"
        :columns="gridOtherImpr.columns"
        :data="gridOtherImpr.data"
        :gridHeight="gridheight2"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId, 'other')"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr('other', 'ITTU000055')" />
            <c-btn v-if="editable && !disabled" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr('other', 'ITTU000055')" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'compliance-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        apprData: {
          flag: false,
          research: '',
        }
      }),
    },
  },
  data() {
    return {
      requestImprRow: null,
      selectedItemRow: {},
      imprData: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      gridheight: '',
      gridheight2: '',
      gridItem: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            style: 'width:180px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'custom',
            field: 'custom',
            label: '',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'itemMstName' },
        ],
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'beforeImage',
            field: 'beforeImage',
            label: '개선 전 사진',
            align: 'center',
            style: 'width:100px',
            type: 'img',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'IBM_BEFORE',
            keyText: 'sopImprovementId',
            sortable: false,
          },
          {
            name: 'afterImage',
            field: 'afterImage',
            label: '개선 후 사진',
            align: 'center',
            style: 'width:100px',
            type: 'img',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'IBM_AFTER',
            keyText: 'sopImprovementId',
            sortable: false,
          },
        ],
        data: [],
      },
      gridOtherImpr: {
        columns: [
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'beforeImage',
            field: 'beforeImage',
            label: '개선 전 사진',
            align: 'center',
            style: 'width:100px',
            type: 'img',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'IBM_BEFORE',
            keyText: 'sopImprovementId',
            sortable: false,
          },
          {
            name: 'afterImage',
            field: 'afterImage',
            label: '개선 후 사진',
            align: 'center',
            style: 'width:100px',
            type: 'img',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'IBM_AFTER',
            keyText: 'sopImprovementId',
            sortable: false,
          },
        ],
        data: [],
      },
      getUrl: '',
      impListUrl: '',
      impSaveUrl: '',
      impDeleteUrl: '',
      editable: true,
      planData: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        isSave: false,
      },
      itemListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return Boolean(this.planData.sysApprovalRequestId)
    },
    isRowClick() {
      return Boolean(this.selectedItemRow.selfInspectionResultItemMstId)
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'param.apprData.research'() {
      this.getInfo();
    }
  },
  methods: {
    init() {
      this.gridheight2 = (window.innerHeight - 608) + 'px';
      this.gridheight = '450px';
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.pca.result.info.get.url;
      this.itemListUrl = selectConfig.sop.pca.resultItemMst.url;
      this.improveUrl = selectConfig.sop.pca.improve.list.url;
      this.impListUrl = selectConfig.sop.pca.improve.others.url;
      
      this.getInfo();
      this.getItemList();
      // this.getImpList();
      this.getImpOtherList();
    },
    getInfo() {
      this.$http.url = this.$format(this.getUrl, this.param.selfInspectionResultId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.planData = _result.data;
      },);
    },
    getItemList() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionResultId: this.param.selfInspectionResultId,
      };
      this.$http.request((_result) => {
        this.gridItem.data = _result.data;
      },);
    },
    getImpList(selfInspectionResultItemMstId) {
      this.$http.url = this.improveUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionResultId: this.param.selfInspectionResultId,
        selfInspectionResultItemMstId: selfInspectionResultItemMstId,
      
      };
      this.$http.request((_result) => {
        this.gridImpr.data = _result.data;
      },);
    },
    rowClick(row) {
      this.selectedItemRow = row;
      this.getImpList(row.selfInspectionResultItemMstId);
    },
    getImpOtherList() {
      this.$http.url = this.impListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionResultId: this.param.selfInspectionResultId
      };
      this.$http.request((_result) => {
        this.gridOtherImpr.data = _result.data;
      },);
    },
    // 개선창 닫기 후
    imprChange() {
      this.getImpList(this.selectedItemRow.selfInspectionResultItemMstId);
    },
    linkClick(row, ibmClassCd, sopImprovementId, flag) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.requestImprRow = row
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    addImpr(flag, ibmTaskUnderTypeCd) {
      let requestContents = flag === 'item' ? this.selectedItemRow.itemMstName : this.planData.selfInspectionTitle;
      let relationTableKey = flag === 'item' ? this.selectedItemRow.selfInspectionResultItemMstId : this.param.selfInspectionResultId;
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: relationTableKey,
        ibmTaskTypeCd: 'ITT0000035',
        ibmTaskUnderTypeCd: ibmTaskUnderTypeCd, 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    addiimImpr(flag, ibmTaskUnderTypeCd) {
      let requestContents = flag === 'item' ? this.selectedItemRow.itemMstName : this.planData.selfInspectionTitle;
      let relationTableKey = flag === 'item' ? this.selectedItemRow.selfInspectionResultItemMstId : this.param.selfInspectionResultId;
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: relationTableKey,
        ibmTaskTypeCd: 'ITT0000035',
        ibmTaskUnderTypeCd: ibmTaskUnderTypeCd, 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList(this.selectedItemRow.selfInspectionResultItemMstId);
    },
    closeImprOtherPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpOtherList();
    },
  }
};
</script>
